var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("el-col", [_c("h1", [_vm._v("欢迎使用可达鸭加盟校管理系统")])]),
      _c("change-pass", {
        attrs: {
          show: _vm.changePassDialog,
          showClose: _vm.showClose,
          closeModal: _vm.closeModal,
        },
        on: {
          close: function ($event) {
            _vm.changePassDialog = false
          },
          savePass: _vm.savePass,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }