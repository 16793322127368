<template>
  <el-row class="table">
    <el-col>
      <h1>欢迎使用可达鸭加盟校管理系统</h1>
    </el-col>
    <change-pass :show="changePassDialog" @close="changePassDialog=false" @savePass="savePass" :showClose='showClose' :closeModal='closeModal'></change-pass>
  </el-row>
</template>
<script>
import ChangePass from '../../views/public/user/ChangePass/index'
import {userEditPwd,passwordCheck} from '@/api/user'
export default {
  components: {
    ChangePass
  },
  data () {
    return {
      showClose:false,
      closeModal:true,
      changePassDialog:false,
    }
  },
  mounted(){
    let passSafe = localStorage.getItem('passSafe')
    if(passSafe === 'N') {
        
      this.changePassDialog = true
    }
  },
  methods:{
    // 保存密码
    async savePass (data) {
      let passData = {
        pwdOld: data.originalPass,
        pwdNew: data.newPass
      }
      // 密码校验
      const formdate = new FormData();
      formdate.append('password',data.newPass,)
      const res = await passwordCheck(formdate)
      if(!res.body){
        window.$msg('当前新密码不符合密码规范，请重新填写密码(密码必须包含大写字母、小写字母、数字、特殊字符中的三种)', 2)
        return
      }
      await userEditPwd({ ...passData })
      this.$store.dispatch('user/loginOut').then(() => {
        window.$msg('密码修改成功,请重新登录', 2)
        this.close()
        this.changePassDialog = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  @import "../../style/table.scss";
.home-card {
  margin-bottom: 20px;
}
.home-card {
  padding: 10px;
  background: #fff;
}
.home-table {
  margin: 25px auto 45px;
}
.home-tit {
  font-size: 15px;
  text-align: left;
  color: #333333;
  font-weight: 400;
}

.home-refresh {
  display: inline-block;
  font-weight: 600;
  vertical-align: sub;
  margin-left: 20px;
  margin-top: 2px;
}
</style>
